// CHALLENGES

.field-type-taxonomy-term-reference {
	margin-bottom: 1em;
}

.user-info-picture {
	img {
		width: 40px;
		float: left;
		border-radius: 100%;
		margin-right: (10px/14px)*1rem;
	}
}

.challenge-prize-div,
.challenge-complexity-div,
.challenge-status-div {
	margin-bottom: (8px/14px)*1rem;
}

.challenge-prize-label,
.challenge-complexity-label,
.challenge-status-label {
	font-size: 1rem;
	font-weight: 500;
	margin-right: .5em;
	color: #777;
}

.field-level-of-complexity {
	font-size: 1.1em;
	color: #FFF;
	padding: 0.1em 0.3em;
	line-height: 2em;
}
.Task {
	background-color: green;
}

.Challenge {
	background-color: orange;
}

.Grand {
	background-color: black;
}


.challenge-prize-value {
	line-height: 1.6em;
	font-size: 1.1em;
	font-weight: 600;
}

.challenge-status-value {
	font-weight: 600;
	padding: 0.3em;
	text-align: center;
	background-color: rgba(255, 255, 0, .2);
	margin-bottom: 1em;
}

.flag-upvote-challenge {
	border-radius: 3px;
	box-shadow: 0 1px 1px 0 rgba(200, 200, 200, .2);
	font-weight: 500;
	padding: 3px 3px 4px 7px;
	text-align: center;
	background: #f1f8fb;
	border: 1px solid #bbcadc;

	a.flag {
		font-size: (15px/15px)*1rem !important;
	}

	.glyphicon {
		font-size: .9em;
		margin-right: .3em;
	}

	&:hover {
		border-color: #1e7684;
	}

	.flag-count {
		margin-left: 3px;
		padding-left: 5px;
		font-size: .9em;
		border-left: 1px solid #bbcadc;
	}
}


.challenges-list > h3 {
	margin: 0.3rem 0;
}

.tags {
	margin-bottom: 1rem;
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	ul > li {
		display: inline-block;
		margin-right: 0.2rem;
		font-size: (13px/15px)*1rem;
		background-color: rgba(24, 96, 107, .1);
		padding: .2em .5em;
	}
}

.challenge-icon {
	padding: 0.3rem;
	border-radius: 50%;
	color: #FFF;
}

.challenge-icon.Tasks {
	background-color: green;
}

.challenge-icon.Challenges {
	background-color: orange
}

.challenge-icon.Grand-Challenges {
	background-color: DarkSlateGray;
}