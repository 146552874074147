// Typefaces
$typeface-default: "open-sans", "Helvetica Neue", sans-serif;

// Colors
$color-gpii-orange: #e44e31;
$color-gpii-sai-primary: #A72534;
$color-gpii-blue: #2783c6;
$color-gpii-dark-blue: #3391d8;
$color-gpii-light-gray: #eceeec;
$color-gpii-gray: #e5e5e5;
$color-gpii-med-gray: #b2b2b2;
$color-gpii-dark-gray: #2c3849;
$color-fluidui-beige: #b9b2ab;
$color-fluidui-gray: #191919;
$color-fluidui-dark-gray: #2f2b2a;
$color-gpii-devspace-primary: #18606b;

// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab');
// Fixes for the core variables.
$icon-font-path: '../bootstrap/bootstrap/assets/fonts/bootstrap/';

// Bootstrap core variables.
@import '../bootstrap/bootstrap/assets/stylesheets/bootstrap/variables';

// Mixins
@mixin primary-column-width($offset: 0px) {
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $screen-sm-min) { width: $container-sm - $grid-gutter-width + $offset; }
  @media (min-width: $screen-md-min) { width: $container-md - $grid-gutter-width + $offset; }
  @media (min-width: $screen-lg-min) { width: $container-lg - $grid-gutter-width + $offset; }
}
