// ACTION LINKS

.group-action-links {
	display: block;
	clear: both;
	overflow: visible;

	a.flag,
	a.rate,
	a.twitter-share,
	a.linkedin-share,
	a.flag:visited, {		
		color: #777;

		&:hover {
			color: $color-gpii-devspace-primary;
		}

		.fa-bookmark {
			color: $color-gpii-devspace-primary;
		}
	}
}

// FULL PAGE
.view-mode-full {

	.group-action-links {

		margin-bottom: (24px/15px)*1rem;

		.h1 {
			margin: 0;
		}

		#widget_stars_rate_part.widget_stars {
			float: left;
		}


		#widget_stars_rate_part.widget_stars,
		.flag-outer.flag-outer-bookmarks,
		.flag-outer.flag-outer-upvote-challenge,
		.social-button {
			display: inline-block;
			margin-right: (24px/15px)*1rem;

			.ext {
				display: none;
			}
		}

		a.flag,
		a.rate,
		a.twitter-share,
		a.linkedin-share,
		a.flag:visited, {		
			font-size: (20px/15px)*1rem;
		}
	}

	article {

		.featured-image,
		.field-name-field-image {

			margin-bottom: 1rem;

			@media (max-width: $screen-xs-max) {
				max-width: 100%;
				float: none;
			}
			@media (min-width: $screen-sm-min) {
				max-width: 50%;
				float: left;
				margin-right: 1rem;
			}
		}

		img {
			max-width: 100%;
			margin-right: 1rem;
			margin-bottom: 1rem;
		}
	}

	aside {

		ul {
			list-style-type: none;
			padding: 0;

			li {
				margin-bottom: (7px/15px)*1rem;
			}
		}
	}
}


// COMMENTS
.comment-wrapper {
	margin-top: 2rem;
}


// TEASER
.node-teaser {
	display: block;
	clear: both;
	position: relative;
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-top: 1px solid rgb(226, 226, 226);

	h2 {
		margin-top: 0;
		margin-bottom: (8px/15px)*1rem;
		line-height: (20px/15px)*1rem;
		font-size: (18px/15px)*1rem;

		a {
			font-size: (18px/15px)*1rem;
			font-weight: 600;
		}

		@media (min-width: $screen-md-min) {
			margin-right: (60px/15px)*1rem;
		}
	}

	.node-teaser-subtitle {
		color: #666;
	}

	.node-teaser-body {
		margin-top: (10px/15px)*1rem;
	}

	.group-action-links {

		.flag-throbber {
			font-size: 0;
		}

		a {
			color: #999;
		}

		@media (max-width: $screen-sm-max) {
			.flag-outer.flag-outer-bookmarks,
			.social-button {
				display: inline-block;
				margin-right: (25px/15px)*1rem;
			}
		}

		@media (min-width: $screen-md-min) {
			position: absolute;
			top: 0;
			right: 0;

			.flag-outer.flag-outer-bookmarks,
			.social-button {
				display: inline-block;
				margin-left: (15px/15px)*1rem;
			}
		}
	}

	.tags {
		@media (min-width: $screen-md-min) {
			margin-bottom: 0;
		}
	}

	.question-source {
		.label {
			background-color: #f0ad4e;
		}
		.label.DeveloperSpace {
			background-color: $color-gpii-devspace-primary;
		}

		@media (max-width: $screen-sm-max) {
			margin-bottom: (8px/15px)*1rem;
		}

		@media (min-width: $screen-md-min) {
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}
}

// NODE CARD
.node-card {
	padding: 0;
	margin-bottom: 2rem;
	overflow: hidden;

	.card-header {
		overflow: hidden;

		.img-holder {
			margin: 0 auto 0;
			width: 120px;
			height: 120px;
			vertical-align: middle;
			overflow: hidden;
			border-radius: 50%;
		}
	}

	h2 {
		margin: .5rem 0;
		font-size: (18px/15px)*1rem;
		line-height: (22px/15px)*1rem;
		font-weight: 600;
	}

	.card-content {
		padding: 1rem;

		p {
			font-size: 1rem;
			margin: 1em 0;
		}
	}

	.card-footer {
		padding: .3em 1em 1em 1em;
		overflow: hidden;
		div {
			display: inline-block;
			margin-right: (7px/15px)*1rem;
		}
	}
}

// NODE OVERVIEW

.node-overview-list {
	overflow: hidden;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.node-overview {
	float: none;
	display: inline-block;
	vertical-align: top;
	padding: (7px/15px)*1rem;
	margin-right: -4px;
	margin-bottom: 1em;
	border: 1px solid #FFF;
	transition: border-color .2s ease,
				box-shadow .2s ease;

	.category-icon {
		display: inline-block;
		float: left;

		i.fa {
			font-size: 18px;
			line-height: 22px;
			color: #777;
		}
	}

	h3 {
		margin-top: 0;
    	margin-left: 24px;
    	margin-bottom: (3px/15px)*1rem;
    	font-size: 1rem;
    	line-height: (24px/15px)*1rem;
    	font-weight: 600;
	}

	span {
		font-size: 1rem;
		color: #777;
	}

	.span-published {
		margin-left: 24px;
	}

	&:hover {
		border-color: $color-gpii-gray;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, .1);
		h3 > a {
			color: #009688;
		}
	}
}

// ADD NODE LIST
.node-type-list {
	dt {
		border-top: 1px solid #CCC;
		padding: .5em 0;

		a:before {
			content: "+";
			font-size: 1.1em;
			margin-right: .3em;
			color: #666;
		}
	}
	dd {
		padding-bottom: .5em;
		text-indent: 1em;
	}
}

/*******************************************************************
** VIEWS
*******************************************************************/

.view-header {
	margin-bottom: (8px/15px)*1rem;
}

.page-learn-bibliography,
.page-develop-components {
	.view-header {
		
		@media (min-width: $screen-md-min) {
			float: left;
			margin-top: (32px/15px)*1rem;
		}
	}

	.view-filters {
		@media (min-width: $screen-md-min) {
			float: right;

			.views-exposed-form .views-exposed-widget {
				padding: 0 0 0 1rem;
			}

			.views-submit-button {
				display: none;
			}
		}
	}
}




// CALENDAR
.view .date-nav-wrapper .date-prev {
	left: 0;
	right: auto;
}

// RATING

.widget_stars_rate span.spanstar {
	font-size: 50px;
}

.markdown-body {
	h1 {
		font-size: (26px/15px)*1rem;
	}
	img {
		float: none;
	}
}