.category-items {
	clear: both;
	overflow: hidden;
	margin-bottom: 2rem;

	h2 {
		display: inline-block;
		margin-top: 0;
	}

	.browse-link {
		float: right;
		font-weight: 600;
	}
}