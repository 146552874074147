/***************************************
*
* FEATURED LINKS
*
***************************************/

.region.region-featured-links {
	display: block;
	margin-bottom: 3rem;

	@media (max-width: 520px) {
		width: 100%;
		overflow: scroll;
	}
}

.region-featured-links::-webkit-scrollbar {
    -webkit-appearance: none;
}

.region-featured-links::-webkit-scrollbar:horizontal {
    height: 10px;
}

.region-featured-links::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
}

.region-featured-links::-webkit-scrollbar-track { 
    background-color: #fff; 
    border-radius: 8px; 
} 

.front .region-featured-links {
	margin-bottom: 2rem;

	.container {
		display: flex;
		flex-direction: row;

		@media (max-width: $screen-sm-max) {
			width: 100%;
		}

		@media (max-width: 520px) {
			width: 190%;
		}
	}
}

.front .featured-link-block {
	cursor: pointer;
	border-bottom: 3px solid #FFF;
	color: #333;
	padding: 0 (7px/15px)*1rem;

	img {
		opacity: 0.9;
		margin-bottom: (8px/15px)*1rem;
	}

	.internal-link {
		display: block;
		margin-bottom: (3px/15px)*1rem;
		font-size: (18px/15px)*1rem;
		font-weight: 600;
	}

	&:hover {
		color: #000;
		border-bottom: 3px solid #E5E5E5;

		img {
			opacity: 1;
		}
	}
}

.not-front .region-featured-links {

	@media (max-width: $screen-xs-max) {
		display: none;
	}

	@media (min-width: $screen-sm-min) {
		background-color: #F5F5F5;
		padding-top: (15px/15px)*1rem;	

		.container {
			width: 100%;
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
		}
	}

	@media (min-width: 992px) {
		.container {
			width: 960px;
		}
	}

	@media (min-width: 1200px) {
		.container {
			width: 1160px;
		}
	}
}

.not-front .featured-link-block {

	@media (max-width: $screen-xs-max) {
		display: none;
	}

	@media (min-width: $screen-sm-min) {
		cursor: pointer;
		max-width: 25%;
		flex: 1;
		padding: 0 (7px/15px)*1rem;

		img {
			margin-bottom: (7px/15px)*1rem;
		}

		.internal-link {
			display: block;
			padding: 0 (8px/15px)*1rem;
			margin-bottom: (2px/15px)*1rem;
			font-size: 1rem;
			font-weight: 600;
		}

		p {
			font-size: (14px/15px)*1rem;
			padding: 0 (8px/15px)*1rem;
		}
	}

}

.not-front .bean-featured-link:hover {
	background-color: #FFF;
}

.not-front .bean-featured-link.selected {
	background-color: #FFF;

	.internal-link {
		color: #000;
	}
}


/***************************************
*
* STORIES BEANS
*
***************************************/

.region-stories {
	margin: 0 -15px;
}

@media (max-width: $screen-xs-max) {
	.story-item {
		
		.story-content {
			width: 100%;
		}
		
		.story-image {
			width: 100%;
			margin: 0 auto;
		}
	}
}

@media (min-width: $screen-sm-min) {
	.story-item {
		max-width: 1170px;
		margin: 0 auto 3rem;
		overflow: hidden;
		position: relative;

		.story-content {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			p, a {
				font-size: (17px/15px)*1rem;
			}
		}
		.story-image {
			position: relative;
			overflow: hidden;
			max-height: 320px;
			img {
				display: block;
				max-height: 100%;
				width: auto;
			}
		}
	}

	.views-row-even .story-content,
	.image-on-right .story-content {
		padding-left: 96px;
	}

	.views-row-even .story-image,
	.image-on-right .story-image {
		left: 50%;
	}

	.views-row-odd .story-content, 
	.image-on-left .story-content {
		left: 50%;
		padding-left: 48px;
	}

	.views-row-odd .story-image img, 
	.image-on-left .story-image img {
		float: right;
	}
}

/***************************************
*
* HERO MESSAGE BEANS
*
***************************************/

.hero-message {
	padding-top: 2rem;
	padding-bottom: 2rem;

	h1 {
		color: #000;
		font-weight: 500;
		margin: 0;
		line-height: 44px;
	}
}
