.front {

	.welcome-block {
		margin-top: (36px/15px)*1rem;
		margin-bottom: (28px/15px)*1rem;
	}

	.region-hero {
		position: relative;
		margin-bottom: 1rem;
		text-align: center;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		overflow: hidden;
		background-color: $color-gpii-devspace-primary;
		background-image: url('../images/home_hero_background.jpg');
  		background-repeat: no-repeat;
  		background-size: cover;
  		background-position: 50% 50%;

  		h1 {
  			text-shadow: 1px 1px 1px #333;
  			margin-top: (20px/15px)*1rem;
  			margin-bottom: (10px/15px)*1rem;
  		}

		p {
			width: 60%;
			margin: 0 auto (20px/15px)*1rem;
			font-size: (20px/16px)*1rem;
			line-height: (32px/15px)*1rem;
		}

		p.highlight {
			text-shadow: 1px 1px 1px #333;
		}

		a,
		a:visited {
			font-size: (18px/15px)*1rem;
			color: #000;
		}

		@media (max-width: $screen-sm-max) {
			width: 100%;
			padding: 0 1em 1em 1em;

			h1 {
				font-size: 2rem;
				line-height: 2.6rem;
			}

			p {
				width: 80%;
			}
		}
	}

	#main-container {
		margin-top: 1rem;

		@media (max-width: $screen-sm-max) {
			width: 100%;
		}
	}

	.view {
		clear: both;
		overflow: hidden;
		margin-bottom: 2em;
	}

	.view-header {
		margin-bottom: 1rem;
		text-align: center;
	}

	.view-content {
		overflow: hidden;
	}

	.view-footer {
		clear: both;
		margin-top: 1.5rem;
		text-align: center;

		a {
			font-size: 1rem;
		}
	}
}

.components-block {
	margin-bottom: 3rem;
	padding-bottom: 0;
}

/*********************************************
* FRONT STORIES
*********************************************/

 .front-story {
 	overflow: hidden;
 	padding: 0 1rem;
 	margin-bottom: 75px;
 	background-color: #FFF;

 	@media (max-width: $screen-xs-max) {
 		float: none;
 		width: 100%;
 		margin: 0 auto 2rem;
 	}

 	@media (min-width: $screen-sm-min) {
 		width: 48%;
 		float: left;

 	}
 
 	.story-image {
	 	display: block;
	 	@include aspect-ratio(9,6);
	 	width: 100%;
	 	background-repeat: no-repeat;
	 	background-size: contain;
 	}
 }

.front-story.story-user-groups {
	.story-image {
		background-image: url('../images/groups_banner.png');

		.user-groups-list {
			position: absolute;
			top: 1rem;
			left: 1rem;
			padding: 0;
			list-style-type: none;

			@media (max-width: $screen-sm-max) {
				margin: 1rem 0 0 0;
			}

			@media (min-width: $screen-md-min) {
				margin: 2rem 0 0 0;
			}

			li {
				display: inline-block;

				@media (max-width: $screen-sm-max) {
					margin-bottom: (26px/15px)*1rem;
					margin-right: 0.2rem;
				}


				@media (min-width: $screen-md-min) {
					margin-bottom: (34px/15px)*1rem;
					margin-right: 0.5rem;
				}
				

				a {
					background-color: #FFF;
					border: 1px solid #e5e5e5;
					box-shadow: 1px 1px 1px rgba(0, 0, 0, .25);
					padding: (10px/14px)*1rem;
					border-radius: 3px;

					@media (max-width: $screen-xs-max) {
						font-size: (13px/15px)*1rem;
					}
				}
			}
		}
	}
}

 .front-story.story-testers {
 	.story-image {
 		background-image: url('../images/testers_banner.png');
 	}
 }

 .front-story.story-success {
 	overflow: hidden;

 	.story-image {
 		background-color: #EF3B41;

 		.success-story-text-container {
 			overflow: hidden;
 			position: absolute;
 			top: 1rem;
 			right: 1rem;
 			background-image: url('../images/bocadillo.png');
 			background-position: bottom left;
 			background-repeat: no-repeat;
 			float: right;
 			padding-bottom: 14px;
 			background-position-y: bottom;
 			background-position-x: 25px;

 			@media (max-width: $screen-xs-max) {
 				width: 90%;
 			}

 			@media (min-width: $screen-sm-min) {
 				width: 70%;
 			}
 		}

 		.success-story-text {
 			background-color: #FFF;
 			padding: 1rem;
 			font-size: 1rem;

 			@media (max-width: $screen-sm-max) {
 				font-size: (13px/15px)*1rem;
 			}
 		}

 		.cite-author {
 			position: absolute;
 			bottom: 0;
 			left: 1rem;
 			color: #FFF;
 		}
 	}
 }

.hero-logo {
	width: 100px;
	position: absolute;
	top: 0;
	right: 0;

	img {
		max-width: 100%;
	}
}

.front .region-hero .p4a-logo-block {
	display: block;
	overflow: hidden;
	position: absolute;
	top: 1rem;
	right: 1rem;

	@media (max-width: 767px) {
		width: 80px;
	}

	@media (min-width: 768px) {
		width: 120px;
	}

	img {
		opacity: .8;
		@media (max-width: 767px) {
			width: 80px;
		}
		@media (min-width: 768px) {
			width: 120px;
		}
	}

	p {
		font-size: (12px/15px)*1rem;
		text-align: center;
		margin: 0;
		width: 100%;
	}
}



