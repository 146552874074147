@import "../../gpii_base/scss/variables";
@import "utilities.scss";
@import "partials/navbar.scss";
@import "partials/homepage.scss";
@import "partials/beans.scss";
@import "partials/categories.scss";
@import "partials/nodes/node.scss";
@import "partials/nodes/challenges.scss";
@import "partials/nodes/develop.scss";
@import "partials/nodes/components.scss";
@import "partials/nodes/dswalkthrough.scss";


// #############################################################################
// Color Changes
// #############################################################################

$color-gpii-devspace-bright-green: #009688;

html, body {
  font-size: 15px;
  font-family: open-sans, Helvetica, sans-serif;
}

@media (min-width: 768px) {
  // html:not(.force-mobile-layout) body {
  //   border-top: (140px/15px)*1rem solid transparent;
  // }

  #push {
    clear: both;
    height: 8rem !important;
  }
}

.btn:not(.btn-default) {
  &:visited {
    color: #FFF;
  }
}

dl dt {
  margin-bottom: (10px/15px)*1rem;
}

h1 {
  font-size: (32px/15px)*1rem;
  font-weight: 600;
}

h2 {
  font-size: (24px/15px)*1rem;
  font-weight: 600;
}

h3 {
  font-size: (18px/15px)*1rem;
  font-weight: 600;
}

h4 {
  font-size: (15px/15px)*1rem;
}

article, aside, .region-sidebar-first, .region-sidebar-second {
  h2 {
    margin-top: 0;
    font-size: (18px/15px)*1rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }

  .block, .tag-block {
    margin-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .page-taxonomy-term section.col-sm-24,
  .page-challenges section.col-sm-24,
  .not-front.node-type-page section.col-sm-24 {
    width: 70%;
    margin: 0 15%;
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 3rem;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#views-exposed-form-taxonomy-term-pages-block {
    .views-exposed-form .views-exposed-widget .form-submit {
      margin-top: 0;
    }
}


.region {
  display: block;
  clear: both;
  overflow: visible;
}

.region-hero {
  clear: both;
  background-color: #FFF;
}

#main-container {
  margin-top: 2rem;
}



#footer-wrap {
  border-color: $color-gpii-devspace-primary;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    background-color: $color-gpii-devspace-primary;
}

// Pagination highlight color

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
    background-color: $color-gpii-devspace-primary;
}

.pagination > li {
  & > a, & > span {
    color: $color-gpii-devspace-primary;
    &:hover, &:focus {
      color: $color-gpii-devspace-primary;
    }
  }
}

.btn-primary {
  background-color: $color-gpii-devspace-primary;
  &:focus, &:active, &:hover {
    background-color: darken($color-gpii-devspace-primary, 6%);
  }
}

a {
  color: $color-gpii-devspace-primary;
  &:hover, &:focus, &:active {
    color: darken($color-gpii-devspace-primary, 6%);
  }
  &:visited {
    color: lighten($color-gpii-devspace-primary, 6%);
  }
}

@media (min-width: 768px) {
  #footer-wrap {
    position: relative;
  }
}

.facetapi-facetapi-checkbox-links {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: .25rem;
  }

  a.facetapi-checkbox {
    display: inline;
    margin-left: .25rem;
  }
}

.homepage-news {
  #challenges {
    background-color: rgb(90, 133, 215);
  }

  #calendar {
    background-color: #eceff1;
  }
}

/**************************************************
** SEARCH
***************************************************/

#search-box {
  overflow: visible;
  padding: 1rem;

  @media (min-width: $screen-sm-min) {
    display: none;
  }

  .region-search-box {

    @media (min-width: $screen-sm-min) {
      width: 60%;
      padding-top: 1rem;
      float: right;
      overflow: visible;
    }
  }

  .block-search-api-page {

    form > div {
      display: flex;
      flex-direction: row;
      align-items: start;
    }

    .form-group {
      width: 100%;
      margin-right: 0.5rem;

      .input-group {
        width: 100%;
      }
    }
  }
}

.exposed-form {
  margin-bottom: 2rem;
}

/*******************************************************
** PAGES WITH FACETS
********************************************************/
// Main container page
.faceted-page-container {
  .col-sm-18.col-sm-push-6 {
    border-left: 1px solid #E0E0E0;

    @media (min-width: $screen-md-min) {
      width: 80%;
      padding: 0 24px;
      left: 20%;
    }

    @media (min-width: $screen-lg-min) {
      width: 60%;
    }
  }

  .facets-sidebar {
    padding: 0 24px;

    h2 {
      margin-bottom: 2rem;
    }

    h3 {
      margin-top: 0;
    }

    .block-facetapi {
      margin-bottom: 1.4rem;
    }

    .facetapi-facetapi-checkbox-links {
      margin-bottom: (8px/15px)*1rem;
    }

    @media (min-width: $screen-sm-min) {
      right: 75%;
    }

    @media (min-width: $screen-md-min) {
      width: 20%;
      right: 80%;
    }

    @media (min-width: $screen-lg-min) {
      right: 60%;
    }

  }
}

/******************************************************
* ACCESSIBILITY MASTERLIST
*******************************************************/

#needs-filter {
  display: block;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.needs-filter-row {
  display: block;
  margin-bottom: (8px/15px)*1rem;
}

.count-characters {
  color: #666;
  font-weight: 600;
}

.needs-filter-cell {
  display: inline-block;
  vertical-align: middle;
}

.filter-button {
  overflow: hidden;
  margin-right: 0.5rem;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
  width: 56px;
  height: 56px;
  cursor: pointer;
  background-size: cover;
}

.filter-button:hover,
.filter-button.selected {
  border: 3px solid $color-gpii-devspace-primary;
}

#blind-filter {
  background-image: url('https://ds.gpii.net/sites/developerspace.gpii.net/files/blind_icon.png');
}

#lv-filter {
  background-image: url('https://ds.gpii.net/sites/developerspace.gpii.net/files/low_vision_icon.png');
}

#cll-filter {
  background-image: url('https://ds.gpii.net/sites/developerspace.gpii.net/files/cll_icon.png');
}

#phy-filter {
  background-image: url('https://ds.gpii.net/sites/developerspace.gpii.net/files/phy_icon.png');
}

#dhoh-filter {
  background-image: url('https://ds.gpii.net/sites/developerspace.gpii.net/files/deaf_icon.png');
}
